import { AfterViewInit, Component } from '@angular/core';
import { UnghostSession } from '@k2/common/sessions-state/actions';
import { RootState } from '@k2/common/sessions-state/state';
import { AppStore } from '@k2/common/state/services/app-store';

@Component({
  selector: 'unghost',
  templateUrl: 'unghost.component.html',
  styleUrls: ['unghost.component.scss']
})
export class UnghostComponent implements AfterViewInit {
  msg = 'We are reverting back to your account';

  constructor(private store: AppStore<RootState>) {}

  ngAfterViewInit(): void {
    setTimeout(this.addDot, 700);
    setTimeout(this.addDot, 1400);
    setTimeout(this.addDot, 2100);
    setTimeout(() => {
      this.store.dispatch(new UnghostSession());
    }, 2800);
  }

  private addDot = () => {
    this.msg += '.';
  };
}
