import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'noticeboard-expandable',
  templateUrl: './noticeboard-expandable.component.html',
  styleUrls: ['./noticeboard-expandable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoticeboardExpandableComponent {
  collapsed = true;
  @Input() notes: string;
  @Input() k2Only = true;
  @Output() save = new EventEmitter<string>();

  get visibleNotes() {
    if (this.collapsed) {
      if (this.notes) {
        return this.notes
          .split('\n')
          .slice(0, 2)
          .join('\n');
      }
    }
    return this.notes;
  }

  set visibleNotes(notes: string) {
    this.notes = notes;
  }
}
