import { ApiClient } from '@k2/common/backend/api-client';
import { AssignmentIdentity } from '@k2/common/entities-state/types';
import { map } from 'rxjs/operators';

export class AssignmentAnalysisEndpoint {
  constructor(private api: ApiClient) {}

  fetchFinanceOverviewTransactions = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/analysis/finance`)
      .pipe(map(payload => payload.finance));
  };

  fetchInitiationFiieldsForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/analysis/initiation-fields`)
      .pipe(map(payload => payload.form));
  };

  updateInitiationFiieldsForm = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/initiation-fields`,
      data
    );
  };

  fetchAssignmentDetailsForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/analysis/assignment/details`)
      .pipe(map(payload => payload.form));
  };

  updateAssignmentDetailsForm = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/assignment/details`,
      data
    );
  };

  fetchK2Team = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/analysis/team`);
  };

  updateK2TeamForm = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.patch(`/clients/${clientId}/assignment/${assignmentId}/analysis/team`, data);
  };

  fetchDocuments = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/analysis/documents`)
      .pipe(map(payload => payload.documents));
  };

  fetchAssigneeDocuments = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/analysis/assignee/documents`)
      .pipe(map(payload => payload.documents));
  };

  fetchNewDocumentForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/analysis/documents/new`)
      .pipe(map(payload => payload.form));
  };

  createDocument = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/documents`,
      data
    );
  };

  fetchDocumentForm = ({ clientId, assignmentId }: AssignmentIdentity, documentId: number) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/analysis/documents/${documentId}`)
      .pipe(map(payload => payload.form));
  };

  updateDocument = (
    { clientId, assignmentId }: AssignmentIdentity,
    documentId: number,
    data: any
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/documents/${documentId}`,
      data
    );
  };

  deleteDocument = ({ clientId, assignmentId }: AssignmentIdentity, documentId: number) => {
    return this.api.delete(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/documents/${documentId}`
    );
  };

  fetchPhotos = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/analysis/documents/photos`)
      .pipe(map(payload => payload.photos));
  };

  fetchSource = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/analysis/source`)
      .pipe(map(payload => payload.source));
  };

  fetchPartnersSummary = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/analysis/partner`);
  };

  fetchFeedbackQuestionnaireId = (
    { clientId, assignmentId }: AssignmentIdentity,
    partnerId: number,
    serviceId: number
  ) => {
    return this.api
      .get(
        `/clients/${clientId}/assignment/${assignmentId}/analysis/partner/${partnerId}/service/${serviceId}/form/identifier`
      )
      .pipe(map(payload => payload.questionnaire.identifier));
  };

  createFeedbackQuestionnaireFor = (
    { clientId, assignmentId }: AssignmentIdentity,
    partnerId: number,
    serviceId: number,
    userId: number
  ) => {
    const data = { recipient_users_id: userId };
    return this.api
      .post(
        `/clients/${clientId}/assignment/${assignmentId}/analysis/partner/${partnerId}/service/${serviceId}/forms`,
        data
      )
      .pipe(map(payload => payload.questionnaire.identifier));
  };

  generateFeedback = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api
      .post(
        `/clients/${clientId}/assignment/${assignmentId}/analysis/partner/feedback/questionnaire/generator`,
        data
      )
      .pipe(map(payload => payload.questionnaire.identifier));
  };

  fetchQuestionnaireConfig = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/questionnaire/config`
    );
  };

  updateQuestionnaireConfig = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/questionnaire/config`,
      data
    );
  };

  fetchInitiation = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/analysis/initiation`);
  };

  updateServiceNeed = (
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: number,
    needed: any
  ) => {
    let data = {
      needed
    };
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/initiation/service/${serviceId}`,
      data
    );
  };

  fetchQuestionnaireEmailLog = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/questionnaire/email/log`
    );
  };

  fetchQuestionnaireEmailLogForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/questionnaire/notify/form`
    );
  };

  createInvitationEmail = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/questionnaire/invitations`,
      data
    );
  };

  updatePackageId = ({ clientId, assignmentId }: AssignmentIdentity, packageId: number) => {
    let data = {
      packages_id: packageId
    };

    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/services/package`,
      data
    );
  };

  fetchSection = ({ clientId, assignmentId }: AssignmentIdentity, section: string) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/questionnaire/${section}`
    );
  };

  updateSection = ({ clientId, assignmentId }: AssignmentIdentity, section: string, data: any) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/questionnaire/${section}`,
      data
    );
  };

  fetchAssignmentOverview = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/analysis/overview`);
  };

  fetchMessages = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/analysis/messages`);
  };

  fetchPendingExceptions = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/analysis/exceptions/pending`)
      .pipe(map(payload => payload.exception_list));
  };

  fetchFirstContact = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/analysis/firstcontact`);
  };

  updateFirstContact = ({ clientId, assignmentId }: AssignmentIdentity, type: string) => {
    let data = {};
    data[type] = true;
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/firstcontact`,
      data
    );
  };

  deleteFirstContact = ({ clientId, assignmentId }: AssignmentIdentity, type: string) => {
    return this.api.delete(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/firstcontact/${type}`
    );
  };

  fetchContactEmailForm = ({ clientId, assignmentId }: AssignmentIdentity, email: string) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/analysis/contact/${email}`)
      .pipe(map(payload => payload.email));
  };

  createContactEmail = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(`/clients/${clientId}/assignment/${assignmentId}/analysis/contact`, data);
  };

  fetchQuestionnaireStatus = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/questionnaire/status`
    );
  };

  updateNoticeboard = ({ clientId, assignmentId }: AssignmentIdentity, notes: string) => {
    return this.api.patch(`/clients/${clientId}/assignment/${assignmentId}/analysis/noticeboard`, {
      noticeboard: notes
    });
  };

  createNewAnalysisMessage = (
    { clientId, assignmentId }: AssignmentIdentity,
    data: {
      message_text: string;
      services_id: number;
    }
  ) => {
    return this.api.post(`/clients/${clientId}/assignment/${assignmentId}/analysis/messages`, data);
  };

  fetchEmails = (
    { clientId, assignmentId }: AssignmentIdentity,
    slice: { offset: number; limit: number },
    query?: string
  ) => {
    let path = `/clients/${clientId}/assignment/${assignmentId}/analysis/email/list/${slice.offset}/${slice.limit}`;
    if (query) path += `/${encodeURIComponent(query)}`;

    return this.api.get(path).pipe(map(payload => payload.emails));
  };

  fetchEmail = ({ clientId, assignmentId }: AssignmentIdentity, emailId: number) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/analysis/email/message/${emailId}`)
      .pipe(map(payload => payload.message));
  };
}
