/**
 * ⚠️⚠️⚠️ !!! STOP !!! ⚠️⚠️⚠️
 *
 * PLEASE MAKE SURE THAT YOU NEVER IMPORT THIS FILE DIRECTLY.
 * INSTEAD, PLEASE USE `appConfig.ts` to access any environment/configuration values.
 */
export const environment = {
  stage: 'stg',
  apiUrl: 'https://api.k2relodev.com/',
  googleMapsApiKey: 'AIzaSyANSNatV_yxtcfnnf-CJRh_mnDngv_tXQU',
  filejet: {
    storageId: 'k2-frontend-stage',
    customDomain: null
  },
  fullstoryAccountId: null
};
