import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { booleanFormatter } from '../formatters/boolean';
import { budgetFormatter, isBudget } from '../formatters/budget';

let isString = require('lodash/isString');

@Pipe({
  name: 'formattedValue'
})
export class FormattedValuePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: any): string {
    if (value == null) return value;
    if (typeof value === 'boolean') return booleanFormatter(value);
    if (isBudget(value)) return budgetFormatter(value);
    if (isString(value)) {
      let match = value.match(
        /\d{4}-[01]\d-[0-3]\dT([0-2]\d:[0-5]\d:[0-5]\d)\.\d+([+-][0-2]\d:[0-5]\d|Z)/
      );
      if (match === null) return value;
      if (match[1] === '00:00:00') return this.datePipe.transform(value, 'shortDate', '+0000');
      return this.datePipe.transform(value, 'short');
    }

    return value;
  }
}
