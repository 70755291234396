import {
  AgeDistribution,
  AssignmentStatusReport,
  ChildrenDistribution,
  MaritalStatusDistribution,
  MonthlyInitiations,
  MovementReport,
  TopClientReport,
  TopDestinationReport,
  TopPartnerReport,
  TopServiceReport,
  YearlyInitiations
} from '@k2/common/entities-state/types';
import { addToNow, toISODate } from '@k2/common/helpers';
import { Filter } from '@k2/common/reports/filter';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClient } from '../api-client';

export class ReportsEndpoint {
  constructor(private api: ApiClient) {}

  private fetchReport = <T = any>(reportName: string) => {
    return (filters: Filter[] = []): Observable<T> => {
      const path = toReportPath(reportName)(filters);
      return this.api.get(path);
    };
  };

  private toCsvReportPath = (reportName: string) => {
    return (filters: Filter[]): string => this.api.toUrl(toReportPath(reportName)(filters));
  };

  fetchPopulationLanes = this.fetchReport('klinec/population/lanes');

  fetchServicesPerYear = this.fetchReport('klinec/year-on-year/services-per-year');

  fetchAssigneesAge = this.fetchReport<AgeDistribution[]>('klinec/demographics/assignee-age');

  fetchMaritalStatuses = this.fetchReport<MaritalStatusDistribution[]>(
    'klinec/demographics/marital-status'
  );

  fetchChildrenAmount = this.fetchReport<ChildrenDistribution[]>(
    'klinec/demographics/children-amount'
  );

  fetchStatusesReport = this.fetchReport<AssignmentStatusReport[]>('klinec/dashboard/statuses');

  fetchTop10Clients = this.fetchReport<TopClientReport[]>('klinec/overview/top-10-clients');

  fetchTop10Destinations = this.fetchReport<TopDestinationReport[]>(
    'klinec/overview/top-10-destinations'
  );

  fetchTop10Services = this.fetchReport<TopServiceReport[]>('klinec/overview/top-10-services');

  fetchTop10Partners = this.fetchReport<TopPartnerReport[]>('klinec/overview/top-10-partners');

  fetchMonthlyInitiations = this.fetchReport<MonthlyInitiations[]>('klinec/initiations-monthly');

  fetchCumulativeYearlyInitiations = this.fetchReport<YearlyInitiations[]>(
    'klinec/initiations-year-on-year/cumulative'
  );

  fetchRegionsChord = this.fetchReport('klinec/population/regions-chord');

  fetchClientContacts = this.fetchReport('klinec/mi-reporting/client-contacts');
  toClientContactsCsvPath = this.toCsvReportPath('klinec/csv/mi-reporting/client-contacts');

  fetchInitiations = this.fetchReport('initiations');
  toInitiationsCsvPath = this.toCsvReportPath('csv/initiations');

  fetchDataMonitoring = this.fetchReport('klinec/mi-reporting/data-monitoring');
  toDataMonitoringCsvPath = this.toCsvReportPath('klinec/csv/mi-reporting/data-monitoring');

  fetchSupportedServices = this.fetchReport('klinec/mi-reporting/supported-services');
  toSupportedServicesCsvPath = this.toCsvReportPath('klinec/csv/mi-reporting/supported-services');

  fetchAssignmentsHistory = this.fetchReport('klinec/mi-reporting/full-assignment-history');
  toAssignmentsHistoryCsvPath = this.toCsvReportPath(
    'klinec/csv/mi-reporting/full-assignment-history'
  );

  fetchClientCustomField = this.fetchReport('klinec/mi-reporting/client-custom-field');
  toClientCustomFieldCsvPath = this.toCsvReportPath('klinec/csv/mi-reporting/client-custom-field');

  fetchStorageReport = this.fetchReport('klinec/mi-reporting/storage');
  toStorageReportCsvPath = this.toCsvReportPath('klinec/csv/mi-reporting/storage');

  fetchServicesInProgressReport = this.fetchReport('klinec/mi-reporting/services-in-progress');
  toServicesInProgressReportCsvPath = this.toCsvReportPath(
    'klinec/csv/mi-reporting/services-in-progress'
  );

  fetchNonTenancyManagementReport = this.fetchReport(
    'klinec/mi-reporting/non-tenancy-management-expiry'
  );
  toNonTenancyManagementReportCsvPath = this.toCsvReportPath(
    'klinec/csv/mi-reporting/non-tenancy-management-expiry'
  );

  fetchTempAccommodationExpiryReport = this.fetchReport(
    'klinec/mi-reporting/temp-accommodation-expiry'
  );
  toTempAccommodationExpiryReportCsvPath = this.toCsvReportPath(
    'klinec/csv/mi-reporting/temp-accommodation-expiry'
  );

  fetchHHGVolumesReport = this.fetchReport('klinec/mi-reporting/hhg-volumes');
  toHHGVolumesReportCsvPath = this.toCsvReportPath('klinec/csv/mi-reporting/hhg-volumes');

  fetchInitiationsListReport = this.fetchReport('klinec/mi-reporting/initiations-list');
  toInitiationsListReportCsvPath = this.toCsvReportPath('klinec/csv/mi-reporting/initiations-list');

  fetchInsuranceClaimsReport = this.fetchReport('klinec/mi-reporting/hhg-insurance-claims');
  toInsuranceClaimsReportCsvPath = this.toCsvReportPath(
    'klinec/csv/mi-reporting/hhg-insurance-claims'
  );

  fetchTenancyInvoicingReport = this.fetchReport('klinec/mi-reporting/tenancy-invoicing');
  toTenancyInvoicingReportCsvPath = this.toCsvReportPath(
    'klinec/csv/mi-reporting/tenancy-invoicing'
  );

  fetchTenancyExpiryReport = this.fetchReport('klinec/mi-reporting/tenancy-expiry');
  toTenancyExpiryReportCsvPath = this.toCsvReportPath('klinec/csv/mi-reporting/tenancy-expiry');

  fetchVisaImmigrationReport = this.fetchReport('klinec/mi-reporting/visa-immigration');
  toVisaImmigrationReportCsvPath = this.toCsvReportPath('klinec/csv/mi-reporting/visa-immigration');

  fetchAssignmentsUnansweredFeedbackQuesionsReport = this.fetchReport(
    'klinec/mi-reporting/assignments-unanswered-feedback-questions'
  );
  toAssignmentsUnansweredFeedbackQuesionsReportCsvPath = this.toCsvReportPath(
    'klinec/csv/mi-reporting/assignments-unanswered-feedback-questions'
  );

  fetchAssignmentsFeedbackReport = this.fetchReport(
    'klinec/mi-reporting/assignments-feedback-report'
  );
  toAssignmentsFeedbackReportCsvPath = this.toCsvReportPath(
    'klinec/csv/mi-reporting/assignments-feedback-report'
  );

  fetchAssignmentsWithoutFeedbackReport = this.fetchReport(
    'klinec/mi-reporting/assignments-without-feedback'
  );

  toAssignmentsWithoutFeedbackReportCsvPath = this.toCsvReportPath(
    'klinec/csv/mi-reporting/assignments-without-feedback'
  );

  fetchHHGDeliveryReport = this.fetchReport('klinec/mi-reporting/hhg-delivery');
  toHHGDeliveryReportCsvPath = this.toCsvReportPath('klinec/csv/mi-reporting/hhg-delivery');

  fetchPendingSalesTransactionsReport = this.fetchReport(
    'klinec/mi-reporting/pending-sales-transactions'
  );
  toPendingSalesTransactionsReportCsvPath = this.toCsvReportPath(
    'klinec/csv/mi-reporting/pending-sales-transactions'
  );

  fetchPopulationMovementReport = (clientId: number): Observable<MovementReport> => {
    const fetch = this.fetchReport('klinec/population/movements');

    return combineLatest([movementsBy('start_date'), movementsBy('end_date')]).pipe(
      map(([aboutToStart, aboutToEnd]) => ({ aboutToStart, aboutToEnd }))
    );

    function movementsBy(type: string) {
      return fetch([
        new ClientIdFilter(clientId),
        { name: type, value: `${toISODate(new Date())}_${toISODate(addToNow(90))}` }
      ]);
    }
  };

  fetchFeedbackReport = (clientId: number) => {
    return this.api.get(`/staff/client/${clientId}/report/feedback`);
  };

  fetchClientStorageReport = (clientId: number) => {
    return this.api.get(`/reports/client/${clientId}/report/storage`);
  };

  fetchYearOnYearReports = (clientId: number) => {
    return this.api
      .get(`/reports/client/${clientId}/report/year-on-year`)
      .pipe(map(payload => payload.reports));
  };
}

export class ClientIdFilter implements Filter {
  readonly name = 'client_id';
  readonly value: number;

  constructor(id: number) {
    this.value = id;
  }
}

function toReportPath(reportName: string) {
  return (filters: Filter[]): string => {
    return [
      `/reports/${reportName}`,
      ...filters.map(({ name, value }) => `${name}/${encodeURIComponent(value)}`)
    ].join('/');
  };
}
