import { isNotEmpty } from '@k2/common/helpers';
import { ApiClient } from '@k2/common/backend/api-client';
import { Partner, PartnerContact, PartnerFeedback } from '@k2/common/entities-state/types';
import { RemoteMutatedFile } from '@k2/common/file-jet/file';
import { toPairs } from 'ramda';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class PartnersEndpoint {
  constructor(private api: ApiClient) {}

  fetchPartners = () => {
    return this.api.get(`/staff/vendors`);
  };

  fetchNewPartnerForm = () => {
    return this.api.get(`/staff/vendors/new`).pipe(map(payload => payload.form));
  };

  createPartner = (data: any): Observable<number> => {
    return this.api.post(`/staff/vendors`, data).pipe(map(payload => payload.id));
  };

  fetchPartnerForm = (id: number) => {
    return this.api.get(`/staff/vendors/${id}`).pipe(map(payload => payload.form));
  };

  updatePartner = (id: number, data: any) => {
    return this.api.patch(`/staff/vendors/${id}`, data);
  };

  deletePartner = (id: number) => {
    return this.api.delete(`/staff/vendors/${id}`);
  };

  fetchPartner = (id: number) => {
    return this.api.get(`/staff/vendors/${id}/overview`);
  };

  fetchFeedback = (partnerId: number): Observable<PartnerFeedback> => {
    return (
      this.api
        // todo: implement offset & limit
        // /staff/vendors/{vendorId}/feedback[/offset/{offset}/limit/{limit}]
        .get(`/staff/vendors/${partnerId}/feedback`)
        .pipe(map(payload => payload.feedback))
    );
  };

  updatePartnerNotes = (partnerId: number, notes: string) => {
    const data = { notes };
    return this.api.patch(`/staff/vendors/${partnerId}/notes`, data);
  };

  updatePartnerLogo = (partnerId: number, logo: RemoteMutatedFile) => {
    return this.api.patch(`/staff/vendors/${partnerId}/avatar`, logo);
  };

  deletePartnerLogo = (partnerId: number) => {
    return this.api.delete(`/staff/vendors/${partnerId}/avatar`);
  };

  fetchContacts = (partnerId: number) => {
    return this.api
      .get(`/staff/vendors/${partnerId}/contacts`)
      .pipe(map(payload => payload.contacts));
  };

  fetchNewContactForm = (partnerId: number) => {
    return this.api
      .get(`/staff/vendors/${partnerId}/contacts/new`)
      .pipe(map(payload => payload.form));
  };

  createContact = (partnerId: number, data: any) => {
    return this.api.post(`/staff/vendors/${partnerId}/contacts`, data);
  };

  fetchContactForm = (partnerId: number, contactId: number) => {
    return this.api
      .get(`/staff/vendors/${partnerId}/contacts/${contactId}`)
      .pipe(map(payload => payload.form));
  };

  fetchContact = (partnerId: number, contactId: number) => {
    return this.api.get(`/staff/vendors/${partnerId}/contacts/${contactId}`).pipe(
      map(payload => payload.form.fields),
      map(
        (fields): PartnerContact =>
          ({
            id: fields.user_id.value,
            first_name: fields.first_name.value,
            last_name: fields.last_name.value,
            avatar: fields.avatar.value,
            email: fields.email.value,
            job_title: fields.job_title.value,
            responsibility: fields.responsibility.value,
            telephone_work: fields.telephone.value,
            telephone_mobile: fields.mobile.value,
            main_contact: fields.main_contact.value
          } as any)
      )
    );
  };

  updateContact = (partnerId: number, contactId: number, data: any) => {
    return this.api.patch(`/staff/vendors/${partnerId}/contacts/${contactId}`, data);
  };

  deleteContact = (partnerId: number, contactId: number) => {
    return this.api.delete(`/staff/vendors/${partnerId}/contacts/${contactId}`);
  };

  updateContactAvatar = (partnerId: number, contactId: number, avatar: RemoteMutatedFile) => {
    return this.api.patch(`/staff/vendors/${partnerId}/contacts/${contactId}/avatar`, avatar);
  };

  deleteContactAvatar = (partnerId: number, contactId: number) => {
    return this.api.delete(`/staff/vendors/${partnerId}/contacts/${contactId}/avatar`);
  };

  fetchPrices = (partnerId: number) => {
    return this.api.get(`/staff/vendors/${partnerId}/prices`).pipe(map(payload => payload.prices));
  };

  fetchNewPriceForm = (partnerId: number) => {
    return this.api
      .get(`/staff/vendors/${partnerId}/prices/new`)
      .pipe(map(payload => payload.form));
  };

  createPrice = (partnerId: number, data: any) => {
    return this.api.post(`/staff/vendors/${partnerId}/prices`, data);
  };

  fetchPriceForm = (partnerId: number, priceId: number) => {
    return this.api
      .get(`/staff/vendors/${partnerId}/prices/${priceId}`)
      .pipe(map(payload => payload.form));
  };

  updatePrice = (partnerId: number, priceId: number, data: any) => {
    return this.api.patch(`/staff/vendors/${partnerId}/prices/${priceId}`, data);
  };

  deletePrice = (partnerId: number, priceId: number) => {
    return this.api.delete(`/staff/vendors/${partnerId}/prices/${priceId}`);
  };

  fetchDocuments = (partnerId: number) => {
    return this.api
      .get(`/staff/vendors/${partnerId}/documents`)
      .pipe(map(payload => payload.documents));
  };

  fetchNewDocumentForm = (partnerId: number) => {
    return this.api
      .get(`/staff/vendors/${partnerId}/documents/new`)
      .pipe(map(payload => payload.form));
  };

  createDocument = (partnerId: number, data: any) => {
    return this.api.post(`/staff/vendors/${partnerId}/documents`, data);
  };

  fetchDocumentForm = (partnerId: number, documentId: number) => {
    return this.api
      .get(`/staff/vendors/${partnerId}/documents/${documentId}`)
      .pipe(map(payload => payload.form));
  };

  updateDocument = (partnerId: number, documentId: number, data: any) => {
    return this.api.patch(`/staff/vendors/${partnerId}/documents/${documentId}`, data);
  };

  deleteDocument = (partnerId: number, documentId: number) => {
    return this.api.delete(`/staff/vendors/${partnerId}/documents/${documentId}`);
  };

  searchChildren = (query: string): Observable<Partner[]> => {
    return this.api
      .get(`/staff/vendors/child/search/${encodeURIComponent(query)}`)
      .pipe(map(payload => payload.results));
  };

  search = (query: SearchQuery): Observable<Partner[]> => {
    const params = toPairs(query as any)
      .filter(([name, value]) => value != null && isNotEmpty(value))
      .reduce((all, current) => [...all, ...current], [])
      .join('/');

    return this.api.get(`/staff/vendors/search/${params}`).pipe(map(payload => payload.results));
  };

  searchMultiServices = (query: SearchQuery): Observable<Partner[]> => {
    const params = toPairs(query as any)
      .filter(([name, value]) => value != null && isNotEmpty(value))
      .reduce((all, current) => [...all, ...current], [])
      .join('/');

    return this.api
      .get(`/staff/vendors/search-multiple-services/${params}`)
      .pipe(map(payload => payload.results));
  };

  fetchStorageReport = (partnerId: number) => {
    return this.api.get(`/staff/vendors/${partnerId}/storage`);
  };
}

interface SearchQuery {
  readonly service?: string;
  readonly regionCode?: string;
  readonly stateCode?: string;
  readonly name?: string;
}
