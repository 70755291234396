import { Injectable } from '@angular/core';
import { Backend } from '@k2/common/backend/backend';
import { Navigate } from '@k2/common/routing/actions';
import {
  DeleteSession,
  DELETE_SESSION,
  GhostSession,
  GHOST_SESSION,
  Logout,
  LOGOUT,
  StoreGhostedSession,
  StoreRealSession,
  STORE_GHOSTED_SESSION,
  STORE_REAL_SESSION,
  UnghostSession,
  UNGHOST_SESSION
} from '@k2/common/sessions-state/actions';
import { clearPersistedSessions } from '@k2/common/sessions-state/persistence';
import { asUndoableRest, rest } from '@k2/common/state/effect-utils';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { flatMap, map, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SessionEffects {
  constructor(private actions: Actions, private backend: Backend) {}

  @Effect()
  storeRealSession: Observable<Action> = this.actions.pipe(
    ofType<StoreRealSession>(STORE_REAL_SESSION),
    map(({ session: { type, questionnaireHash } }) => {
      if (type === 'STAFF') return new Navigate('/staff');
      if (type === 'CLIENT') return new Navigate('/client');
      if (type === 'ASSIGNEE') return new Navigate(`/assignee`);
      throw Error(`Unsupported session type: ${type}!`);
    })
  );

  @Effect()
  logout: Observable<Action> = this.actions.pipe(
    ofType<Logout>(LOGOUT),
    tap(() => clearPersistedSessions()),
    switchMap(({ skipApiRequest }) => {
      if (skipApiRequest) return of();
      return this.backend.sessions.logout();
    }),
    tap(redirectToFreshRoot),
    rest
  );

  @Effect()
  ghost: Observable<Action> = this.actions.pipe(
    ofType<GhostSession>(GHOST_SESSION),
    switchMap(action => this.backend.sessions.ghost(action.userId)),
    map(session => new StoreGhostedSession(session))
  );

  @Effect()
  storeGhosted: Observable<Action> = this.actions.pipe(
    ofType<StoreGhostedSession>(STORE_GHOSTED_SESSION),
    map(toRootNavigationAction)
  );

  @Effect()
  unghost: Observable<Action> = this.actions.pipe(
    ofType<UnghostSession>(UNGHOST_SESSION),
    switchMap(() => this.backend.sessions.unghost()),
    map(toRootNavigationAction)
  );

  @Effect()
  deleteSession: Observable<Action> = this.actions.pipe(
    ofType<DeleteSession>(DELETE_SESSION),
    flatMap(action => {
      return this.backend.sessions.deleteSessions(action.sessionId).pipe(asUndoableRest(action));
    })
  );
}

function toRootNavigationAction() {
  return new Navigate('/');
}

/**
 * Hard browser redirect which will clear all in-memory caches.
 */
function redirectToFreshRoot() {
  return (window.location.href = '/');
}

export const featureEffects = [SessionEffects];
