import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { safe } from '@k2/common/helpers';
import { Logout } from '@k2/common/sessions-state/actions';
import {
  LogoutCountdownDialogComponent,
  LogoutCountdownDialogData
} from '@k2/common/sessions/components/logout-countdown-dialog.component';
import { ActionDispatcher } from '@k2/common/state/services/action-dispatcher';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

const warningDuration = 60;
const threshold = 10;

@Injectable()
export class SessionsHttpInterceptor implements HttpInterceptor {
  private expirationTimer;

  constructor(private actions: ActionDispatcher, private readonly dialog: MatDialog) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(this.handleSuccessResponse, this.handleErrorResponse));
  }

  private handleSuccessResponse = (response: HttpResponse<any>) => {
    this.tryUpdateSessionExpiration(response.body);
  };

  private handleErrorResponse = (errorResponse: HttpErrorResponse) => {
    if (hasInvalidSession(errorResponse)) {
      clearTimeout(this.expirationTimer);
      this.actions.dispatch(new Logout({ skipApiRequest: false }));
    } else {
      this.tryUpdateSessionExpiration(errorResponse.error);
    }
  };

  private tryUpdateSessionExpiration = (body: any | null) => {
    const serverTimestamp = safe(() => body.meta.server_timestamp);
    const expiresAt = safe(() => body.meta.session_expires_at);
    if (serverTimestamp == null || expiresAt == null) return;

    clearTimeout(this.expirationTimer);
    const diff = expiresAt - serverTimestamp;
    this.expirationTimer = setTimeout(
      this.showLogoutCountdown,
      (diff - warningDuration - threshold) * 1000
    );
  };

  private showLogoutCountdown = () => {
    const data: LogoutCountdownDialogData = { warningDuration };
    this.dialog.open(LogoutCountdownDialogComponent, { data, disableClose: true });
  };
}

function hasInvalidSession(response: HttpErrorResponse): boolean {
  return (
    response.error &&
    response.error.meta &&
    response.error.meta.status === 403 &&
    response.error.meta.location === '/login'
  );
}

export const httpInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: SessionsHttpInterceptor,
  multi: true
};
