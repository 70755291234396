import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'date-time',
  templateUrl: 'date-time.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateTimeComponent {
  @Input() iso: string; // ISO8601
}
