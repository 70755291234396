import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'deletable-item-options',
  styleUrls: ['deletable-item-options.component.scss'],
  templateUrl: 'deletable-item-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeletetableItemOptionsComponent {
  @Output() delete = new EventEmitter();
  @Input() deleteLink;

  underConfirmation = false;

  tryDelete = () => {
    if (this.deleteLink) {
      this.delete.emit();
    } else {
      this.underConfirmation = true;
    }
  };

  confirmDelete = () => {
    this.underConfirmation = false;
    this.delete.emit();
  };
}
