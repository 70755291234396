import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'noticeboard',
  templateUrl: 'noticeboard.component.html',
  styleUrls: ['noticeboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoticeboardComponent {
  @Input() notes: string;
  @Input() k2Only = true;
  @Output() save = new EventEmitter<string>();
}
