import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BackForwardFilterComponent } from '@k2/common/filters/components/back-forward-filter.component';
import { DateFilterComponent } from '@k2/common/filters/components/date-filter.component';
import { K2SelectFilterComponent } from '@k2/common/filters/components/k2-select-filter.component';
import { SelectFilterComponent } from '@k2/common/filters/components/select-filter.component';
import { TextFilterComponent } from '@k2/common/filters/components/text-filter.component';
import { K2SelectModule } from '@k2/common/k2-select/k2-select.module';
import { UIModule } from '@k2/common/ui/ui.module';

const components = [
  SelectFilterComponent,
  K2SelectFilterComponent,
  TextFilterComponent,
  BackForwardFilterComponent,
  DateFilterComponent
];

@NgModule({
  imports: [CommonModule, UIModule, FormsModule, ReactiveFormsModule, K2SelectModule],
  declarations: components,
  exports: components
})
export class FiltersModule {}
