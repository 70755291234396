import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { stringifyAddress } from '@k2/common/addresses/utils';
import { Address, Coordinates } from '@k2/common/entities-state/types';

@Component({
  selector: 'coordinates-selector',
  templateUrl: 'coordinates-selector.component.html',
  styleUrls: ['coordinates-selector.component.scss']
})
export class CoordinatesSelectorComponent {
  @Input() coordinates: Coordinates;
  @Output() coordinatesChange = new EventEmitter<Coordinates>();
  searchControl = new FormControl();

  @ViewChild('search', { static: true }) searchElementRef: ElementRef;

  private geocoder: Promise<google.maps.Geocoder>;

  constructor(mapsAPILoader: MapsAPILoader) {
    const mapsLoaded = mapsAPILoader.load();

    this.geocoder = mapsLoaded.then(() => new google.maps.Geocoder());

    mapsLoaded.then(() => {
      this.preventSubmit();
      this.initializeAutocomplete();
    });
  }

  private preventSubmit = () => {
    google.maps.event.addDomListener(
      this.searchElementRef.nativeElement,
      'keydown',
      (event: KeyboardEvent) => {
        if (event.keyCode === 13) event.preventDefault();
      }
    );
  };

  private initializeAutocomplete = () => {
    const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
      types: ['address']
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      if (place.geometry == null) return;

      const { location } = place.geometry;

      this.updatePosition({
        lat: location.lat(),
        lng: location.lng()
      });
    });
  };

  selectByAddress = async (address: Address) => {
    const geocoder = await this.geocoder;

    geocoder.geocode({ address: stringifyAddress(address) }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const { location } = results[0].geometry;
        this.updatePosition({
          lat: location.lat(),
          lng: location.lng()
        });
      } else {
        this.updatePosition({ lat: 0, lng: 0 });
      }
    });
  };

  updatePosition = (coordinates: Coordinates) => {
    const normalized = {
      lat: Number.parseFloat(coordinates.lat.toFixed(4)),
      lng: Number.parseFloat(coordinates.lng.toFixed(4))
    };

    this.coordinates = normalized;
    this.coordinatesChange.emit(normalized);
  };
}
