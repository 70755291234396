import { ModuleWithProviders, NgModule } from '@angular/core';
import { handleBatchAction } from '@k2/common/state/batch-action';
import { handleUndo } from 'ngrx-undo';

export const rootMetaReducers = [handleUndo, handleBatchAction];

@NgModule({})
export class StateModule {
  static forRoot(): ModuleWithProviders<StateModule> {
    return {
      ngModule: StateModule
    };
  }
}
