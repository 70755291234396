/**
 * Returns true if every part of `query` is included in `target`.
 */
export function includesParts(target: string, query: string): boolean {
  if (query == null) return true;
  const normalizedTarget = target.toLowerCase();
  const parts = query.toLowerCase().split(' ');

  return parts.every(part => normalizedTarget.includes(part));
}
