import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@k2/app.module';
import { appConfig } from '@k2/common/app-config';
import { safe } from '@k2/common/helpers';
import { bootstrap } from 'src/bootstrap';

const { stage } = appConfig;

if (stage !== 'prod') {
  const prefix = `[${stage.toUpperCase()}]`;
  if (!document.title.startsWith(prefix)) {
    document.title = `${prefix} ${document.title}`;
  }
}

const bootstrapModule = () =>
  platformBrowserDynamic().bootstrapModule(AppModule, { preserveWhitespaces: false });

bootstrap(module, bootstrapModule).catch(err => console.error(err));

// Everlution stateBot extension support.
if (globalThis) {
  globalThis.__STATEBOT__ = {
    name: 'k2relo',
    stage,
    data: () => {
      const sessions = JSON.parse(localStorage.getItem('sessions'));

      return {
        userId: safe(() => sessions.real.user.id, null),
        ghostedUserId: safe(() => sessions.ghosted.user.id, null)
      };
    }
  };
}
