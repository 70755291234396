import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { groupBy } from 'ramda';

const noGroupName = '_NO_GROUP';

@Component({
  selector: 'basic-select',
  templateUrl: 'basic-select.component.html',
  styleUrls: ['basic-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicSelectComponent<T extends number | string> implements OnChanges {
  @Input() id?: string;
  @Input() value?: T;
  @Output() valueChange = new EventEmitter<T>();
  @Input() placeholder?: string;
  @Input() options: SelectOption[];
  @Input() disabled?: boolean;
  @Output() blur = new EventEmitter();

  normalizedOptions: NormalizedOption[];
  groups: Array<{ label: string; options: NormalizedOption[] }>;

  ngOnChanges(changes: SimpleChanges): void {
    const grouped = groupBy((option: SelectOption) => option.group || noGroupName, this.options);

    this.normalizedOptions = grouped[noGroupName];
    this.groups = Object.entries(grouped)
      .filter(([label]) => label !== noGroupName)
      .map(([label, options]) => ({ label, options }));
  }

  get isPlaceholderSelected(): boolean {
    const { value } = this;
    return value === null || value === '';
  }
}

export interface SelectOption<T = string | number> extends NormalizedOption<T> {
  readonly group?: string;
}

interface NormalizedOption<T = string | number> {
  readonly id: T;
  readonly value: string;
}
