import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { AppState } from '@k2/app-state';
import { Navigate } from '@k2/common/routing/actions';
import { getSession, getSessionType } from '@k2/common/sessions-state/reducers';
import { SessionType } from '@k2/common/sessions-state/session';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(getSession),
      tap(session => {
        if (session == null) return;

        const { type, questionnaireHash } = session;

        if (type === 'ASSIGNEE') {
          this.store.dispatch(new Navigate(`/assignee`));
        } else if (type === 'CLIENT') {
          this.store.dispatch(new Navigate('/client'));
        } else if (type === 'STAFF') {
          this.store.dispatch(new Navigate('/staff'));
        }
      }),
      map(session => session == null)
    );
  }
}

@Injectable({ providedIn: 'root' })
export class AssigneeGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (state.url.startsWith('/assignee/feedback')) return true;
    return guard(this.store, 'ASSIGNEE');
  }
}

@Injectable({ providedIn: 'root' })
export class ClientGuard implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return guard(this.store, 'CLIENT');
  }
}

@Injectable({ providedIn: 'root' })
export class StaffGuard implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return guard(this.store, 'STAFF');
  }
}

function guard(store: Store<AppState>, allowedType: SessionType): Observable<boolean> {
  return store.pipe(
    select(getSessionType),
    first(),
    tap(type => {
      if (type !== allowedType) store.dispatch(new Navigate('/auth'));
    }),
    map(type => type === allowedType)
  );
}
