import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

/**
 * A common submit control for all forms.
 *
 * It contains a submit button with a projected content
 * and optionally a cancel link.
 *
 * Cancel link could be a link to another page and also a
 * (cancel) event emitter.
 *
 * Component will disable a browser validation of a parent form,
 * so "novalidate" is not necessary.
 *
 * EXAMPLE:
 *   <form>
 *     <submit-control>Save</submit-control>
 *   </form>
 *
 *   <form>
 *     <submit-control cancelLink="../">Save</submit-control>
 *   </form>
 *
 *   <form>
 *     <submit-control (cancel)="doSomething()">Save</submit-control>
 *   </form>
 */
@Component({
  selector: 'submit-control',
  templateUrl: 'submit-control.component.html',
  styleUrls: ['submit-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmitControlComponent {
  @Input() cancelLink?: any;
  @Input() cancelQueryParams?: any;
  @Input() cancelLabel = 'cancel';
  @Input() icon: 'ok' | 'send' = 'ok';
  @Output() cancel = new EventEmitter<void>();
  @Output() onSubmit = new EventEmitter<void>();

  isLoading = false;
  isDisabled = false;

  constructor(private cd: ChangeDetectorRef) {}

  get isCancellable(): boolean {
    return this.cancelLink != null || this.cancel.observers.length > 0;
  }

  @Input()
  set loading(loading: boolean) {
    this.isLoading = loading;
    this.cd.markForCheck();
  }

  @Input()
  set disabled(disabled: boolean) {
    this.isDisabled = disabled;
    this.cd.markForCheck();
  }
}
