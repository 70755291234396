import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ListDialogComponent } from '@k2/common/dialogs/components/list/list-dialog.component';
import { MapDialogComponent } from '@k2/common/dialogs/components/map/map-dialog.component';
import { TextDialogComponent } from '@k2/common/dialogs/components/text/text-dialog.component';
import { MaterialModule } from '@k2/common/material/material.module';

const components = [TextDialogComponent, ListDialogComponent, MapDialogComponent];

@NgModule({
  imports: [CommonModule, MaterialModule],
  entryComponents: components,
  declarations: components,
  exports: components
})
export class DialogsModule {}
