import { Session } from '@k2/common/sessions-state/session';
import { Action } from '@ngrx/store';

export const STORE_REAL_SESSION = '[Sessions] Store real';
export const LOGOUT = '[Sessions] Logout';
export const GHOST_SESSION = '[Sessions] Ghost';
export const STORE_GHOSTED_SESSION = '[Sessions] Store ghosted';
export const UNGHOST_SESSION = '[Sessions] Unghost';
export const DELETE_SESSION = '[Sessions] Delete';

export type Actions =
  | StoreRealSession
  | Logout
  | GhostSession
  | StoreGhostedSession
  | UnghostSession
  | DeleteSession;

export class StoreRealSession implements Action {
  readonly type = STORE_REAL_SESSION;

  constructor(readonly session: Session) {}
}

export class Logout implements Action {
  readonly type = LOGOUT;
  readonly skipApiRequest: boolean;

  constructor(params: { skipApiRequest: boolean } = { skipApiRequest: false }) {
    this.skipApiRequest = params.skipApiRequest;
  }
}

export class GhostSession implements Action {
  readonly type = GHOST_SESSION;

  constructor(readonly userId: number) {}
}

export class StoreGhostedSession implements Action {
  readonly type = STORE_GHOSTED_SESSION;

  constructor(readonly session: Session) {}
}

export class UnghostSession implements Action {
  readonly type = UNGHOST_SESSION;
}

export class DeleteSession implements Action {
  readonly type = DELETE_SESSION;

  constructor(readonly userId: number, readonly sessionId: string) {}
}
