import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'text-dialog',
  templateUrl: 'text-dialog.component.html',
  styleUrls: ['text-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: TextDialogData) {}
}

export interface TextDialogData {
  title: string;
  text: string;
}
