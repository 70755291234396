import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { Backend } from '@k2/common/backend/backend';
import { Location } from '@k2/common/entities-state/types';
import { LocationAutocompleteOptionComponent } from '@k2/common/locations/components/location-autocomplete-option/location-autocomplete-option.component';
import { LocationIngotComponent } from '@k2/common/locations/components/location-ingot/location-ingot.component';
import { ComponentSpec } from '@k2/common/ui/component-spec';
import { AutocompleteSelectorConfig } from '@k2/common/ui/components/autocomplete-selector/autocomplete-selector.component';

@Component({
  selector: 'locations-selector',
  templateUrl: 'locations-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationsSelectorComponent implements OnChanges {
  @Input() config: LocationSelectorConfig;
  @Input() regionId: number;
  @Input() selected: Location[];
  @Output() selectedChange = new EventEmitter<Location[]>();

  selectorConfig: AutocompleteSelectorConfig<Location>;

  constructor(private backend: Backend) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.regionId != null && changes.regionId.previousValue != null) {
      this.selected = [];
      this.update(this.selected);
    }

    if (changes.regionId == null) return;

    this.selectorConfig = {
      query: (query: string) => this.backend.countries.queryLocation(this.regionId, query),
      toId: (location: Location) => location.id,
      toName: (location: Location) => location.city_utf8,
      toSelectedSpec: (location: Location): ComponentSpec<LocationIngotComponent> => ({
        component: LocationIngotComponent,
        inputs: { location }
      }),
      toOptionSpec: (
        location: Location,
        query: string
      ): ComponentSpec<LocationAutocompleteOptionComponent> => ({
        component: LocationAutocompleteOptionComponent,
        inputs: { location, highlight: query }
      }),
      multiple: this.config.multiple,
      placeholder: this.config.placeholder || 'Search city',
      disabled: this.regionId == null
    };
  }

  update = (selected: Location[]) => {
    this.selectedChange.emit(selected);
  };
}

export interface LocationSelectorConfig {
  readonly multiple: boolean;
  readonly placeholder: string;
}
