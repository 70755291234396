/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "../message.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./preparing.component";
var styles_PreparingComponent = [i0.styles];
var RenderType_PreparingComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_PreparingComponent, data: {} });
export { RenderType_PreparingComponent as RenderType_PreparingComponent };
export function View_PreparingComponent_0(_l) { return i1.ɵvid(2, [], null, null); }
export function View_PreparingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_PreparingComponent_0, RenderType_PreparingComponent)), i1.ɵdid(1, 49152, null, 0, i2.PreparingComponent, [], null, null)], null, null); }
var PreparingComponentNgFactory = /*@__PURE__*/ i1.ɵccf("ng-component", i2.PreparingComponent, View_PreparingComponent_Host_0, {}, {}, []);
export { PreparingComponentNgFactory as PreparingComponentNgFactory };

