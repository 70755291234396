import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { toPairs } from 'ramda';

@Component({
  selector: 'map-dialog',
  templateUrl: 'map-dialog.component.html',
  styleUrls: ['map-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapDialogComponent {
  pairs: Array<[string, string | number]>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: MapDialogData) {
    this.pairs = toPairs(data.map);
  }
}

export interface MapDialogData {
  title: string;
  map: {
    [key: string]: string | number;
  };
}
