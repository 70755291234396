import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'link-ingot',
  templateUrl: 'link-ingot.component.html',
  styleUrls: ['link-ingot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkIngotComponent {
  @Input() name: string;
  @Input() link: any;
}
