import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { K2SelectOption } from '@k2/common/k2-select/k2-select-option';
import { FormControl } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'k2-select',
  templateUrl: 'k2-select.component.html',
  styleUrls: ['k2-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class K2SelectComponent implements OnChanges, OnDestroy {
  @Input() value: number | string;
  @Input() options: K2SelectOption[];
  @Input() placeholder = 'Please select...';
  @Input() disabled = false;

  @Output() valueChange = new EventEmitter<number | string>();
  @Output() openedChange = new EventEmitter<boolean>();
  filterCtrl: FormControl = new FormControl();
  filteredOptions: K2SelectOption[];
  alive = false;

  constructor() {
    this.alive = true;
    this.filterCtrl.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(val => {
      if (val) {
        this.filteredOptions = this.options.filter(({ text }) =>
          text.toLowerCase().includes(val.toLowerCase())
        );
      } else {
        this.filteredOptions = this.options;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && !changes.options.previousValue) {
      this.filteredOptions = this.options;
    }
  }

  changeValue() {
    this.filterCtrl.patchValue(null);
    this.valueChange.emit(this.value);
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
