<icon *ngIf="todo.status === 'pending'" type="remove" color="red"></icon>
<icon *ngIf="todo.status === 'completed'" type="ok" color="green"></icon>
<a
  *ngIf="todo.link != null"
  [routerLink]="routerLinkElements"
  [queryParams]="params"
>
  {{ todo.text }}</a
>
<span *ngIf="todo.link == null">{{ todo.text }}</span>
