import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FilterOption, ignoreValue } from '@k2/common/filters';
import { K2SelectOption } from '@k2/common/k2-select/k2-select-option';

@Component({
  selector: 'k2-select-filter',
  templateUrl: 'k2-select-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class K2SelectFilterComponent {
  @Input() control: FormControl;
  @Input() options: FilterOption[];
  @Input() placeholder = 'all';

  get selectOptions(): K2SelectOption[] {
    const options = this.options || [];
    const select2Options = options.map(option => ({
      id: option.value,
      text: option.label
    }));

    if (this.placeholder == null) return select2Options;

    return [{ id: ignoreValue, text: this.placeholder }, ...select2Options];
  }
}
