import { ApiClient } from '@k2/common/backend/api-client';
import { Session } from '@k2/common/sessions-state/session';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class SessionsEndpoint {
  constructor(private readonly api: ApiClient) {}

  login = (params: {
    email: string;
    password: string;
    rememberMe: string;
  }): Observable<{ requires2fa: boolean; session: Session }> => {
    return this.api.post('/user/login', params).pipe(
      map(payload => ({
        requires2fa: payload.requires2fa,
        session: toSession(payload)
      }))
    );
  };

  submit2faCode(code: string) {
    return this.api.post('/user/login/2fa', { code });
  }

  submit2faRecoveryCode(code: string) {
    return this.api.post('/user/login/2fa-recovery', { code });
  }

  logout = (): Observable<void> => {
    return this.api.delete(`/user/logout`);
  };

  ghost = (userId: number): Observable<Session> => {
    return this.api.get(`/user/${userId}/ghost`).pipe(map(toSession));
  };

  unghost = (): Observable<void> => {
    return this.api.get('/user/unghost');
  };

  requestPasswordReset = (email: string): Observable<void> => {
    return this.api.post('/user/password/reset', { email });
  };

  resetPassword = (params: { email: string; password: string; resetCode: string }) => {
    return this.api.post('/user/password/new', params).pipe(map(payload => payload.session));
  };

  fetchCurrentSessions = () => {
    return this.api.get('/sessions').pipe(map(payload => payload.sessions));
  };

  deleteSessions = (sessionIds: string | string[]) => {
    const ids = Array.isArray(sessionIds) ? sessionIds.join(',') : sessionIds;

    return this.api.delete(`/sessions/${ids}`).pipe(map(payload => payload.sessions));
  };
}

function toSession({ session }): Session {
  return {
    user: session.user,
    type: session.type,
    clientId: session.clientId,
    questionnaireHash: session.questionnaireHash
  };
}
