import { CurrencyPipe } from '@angular/common';
import { currentLocale } from '@k2/common/locale.module';

export function budgetFormatter(value: BudgetValue): string {
  let currencyPipe = new CurrencyPipe(currentLocale);
  let { frequency } = value;

  return (
    currencyPipe.transform(value.amount, value.currency_iso3, 'symbol', '1.2-2') +
    ' (' +
    frequency +
    ')'
  );
}

export function isBudget(value): boolean {
  return (
    value.amount !== undefined && value.frequency !== undefined && value.currency_iso3 !== undefined
  );
}

type BudgetValue = {
  amount: number;
  frequency: string;
  currency_iso3: string;
};
