import { isPlainObject } from '@k2/common/helpers';
import { FormSpec, ValueOption } from '@k2/common/k2-forms-state/types';
import { mergeDeepRight } from 'ramda';

export function normalizeValueOption(option): ValueOption {
  if (option.id === undefined) {
    return { id: option, value: option };
  }

  return option;
}

export function toFormSpecNormalizer(...normalizableFields: string[]) {
  return {
    forBackend: data =>
      normalizableFields.reduce(
        (current, fieldName) => simplifyValueObject(fieldName, current),
        data
      ),
    forFrontend: (spec: FormSpec): FormSpec =>
      normalizableFields.reduce(
        (current, fieldName) => copyDisplayValueToValue(fieldName, current),
        spec
      )
  };
}

function simplifyValueObject(fieldName: string, data: any) {
  if (!(isPlainObject(data[fieldName]) && data[fieldName].id != null)) return data;

  return { ...data, [fieldName]: data[fieldName].id };
}

function copyDisplayValueToValue(fieldName: string, spec: FormSpec): FormSpec {
  const { displayValue } = spec.fields[fieldName].attributes;
  return mergeDeepRight(spec, {
    fields: {
      [fieldName]: {
        value: displayValue
      }
    }
  });
}
