import { ApiClient } from '@k2/common/backend/api-client';
import { Location } from '@k2/common/entities-state/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class CountriesEndpoint {
  constructor(private http: ApiClient) {}

  queryLocation = (regionId: number, query: string): Observable<Location[]> => {
    return this.http
      .get(`/countries/${regionId}/locations/${encodeURIComponent(query)}`)
      .pipe(map(payload => payload.locations));
  };
}
