import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FilterOption, ignoreValue } from '@k2/common/filters';

@Component({
  selector: 'select-filter',
  templateUrl: 'select-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectFilterComponent {
  @Input() control: FormControl;
  @Input() options: FilterOption[];
  @Input() placeholder = 'all';

  get ignoreValue() {
    return ignoreValue;
  }
}
