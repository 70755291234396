import { Injectable } from '@angular/core';
import { ADD_MESSAGE, AddMessage, RemoveMessage } from '@k2/common/flash-messages/actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';

const msgTimeout = 5000;

@Injectable({ providedIn: 'root' })
export class FlashMessageEffects {
  constructor(private readonly actions: Actions) {}

  @Effect()
  timeoutMessage: Observable<RemoveMessage> = this.actions.pipe(
    ofType<AddMessage>(ADD_MESSAGE),
    delay(msgTimeout),
    map(action => new RemoveMessage(action.message))
  );
}
