import { ActionReducer } from '@ngrx/store';

const key = 'sessions';

/**
 * An NGRX meta reducer which is able to keep sessions in store between reloads.
 */
export function handleSessionsPersistence(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: any) => {
    if (state == null) {
      const persisted = localStorage.getItem(key);
      if (persisted != null) {
        state = JSON.parse(persisted);
      }
    }

    const newState = reducer(state, action);

    if (state != null && state !== newState) {
      localStorage.setItem(key, JSON.stringify(newState));
    }

    return newState;
  };
}

export function clearPersistedSessions() {
  localStorage.removeItem(key);
}
