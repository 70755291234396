import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';

// todo: Fix a strange behaviour of datepicker when user uses backspace
@Component({
  selector: 'date-filter',
  templateUrl: 'date-filter.component.html',
  styleUrls: ['date-filter.component.scss']
})
export class DateFilterComponent {
  @Input() control: FormControl;
  @Input() placeholder = 'Click to choose';

  get rawValue() {
    return moment.utc(this.control.value);
  }

  set rawValue(date) {
    if (date == null) {
      this.control.patchValue(null);
    } else {
      this.control.patchValue(date.add(date.utcOffset(), 'minutes').toISOString());
    }
  }
}
