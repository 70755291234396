import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'alert-bar',
  templateUrl: 'alert-bar.component.html',
  styleUrls: ['alert-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertBarComponent {
  @Input() noIcon = false;
}
