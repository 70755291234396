import { Component } from '@angular/core';
import { SimpleTabComponent } from './simple-tab.component';
import { equals } from 'ramda';

@Component({
  selector: 'simple-tabs',
  templateUrl: 'simple-tabs.component.html',
  styleUrls: ['simple-tabs.component.scss']
})
export class SimpleTabsComponent {
  tabs: SimpleTabComponent[] = [];
  activeIndex = 0;

  selectTab(tab: SimpleTabComponent) {
    this.tabs.forEach(tab => {
      tab.active = false;
    });
    tab.active = true;
    this.activeIndex = this.tabs.findIndex(item => item.active)
  }

  addTab(tab: SimpleTabComponent) {
    this.tabs.push(tab);
    if (this.tabs.length === 0) {
      tab.active = true;
    } else if (this.tabs[this.activeIndex]) {
      this.tabs[0].active = false;
      this.tabs[this.activeIndex].active = true;
    }
  }

  deleteTab(tab: SimpleTabComponent) {
    this.tabs = this.tabs.filter(item => equals(tab, item));
  }
}
