import { ApiClient } from '@k2/common/backend/api-client';
import { ReassignmentRequest } from '@k2/common/tasks-state/types';
import { fromPairs } from 'ramda';
import { map } from 'rxjs/operators';
import { AssignmentIdentity } from '@k2/common/entities-state/types';
import { Observable } from 'rxjs';

export class TasksEndpoint {
  constructor(private api: ApiClient) {}

  fetchTeamTasks = (userId: number) => {
    return this.api.get(`/staff/tasks/user/${userId}`).pipe(map(payload => payload.tasks));
  };

  markTaskAsDone = (userId: number, taskId: number) => {
    return this.api.patch(`/staff/tasks/user/${userId}/profile/${taskId}`, { status: 'DONE' });
  };

  deleteTask = (userId: number, taskId: number) => {
    return this.api.delete(`/staff/tasks/user/${userId}/profile/${taskId}`);
  };

  reassignTasks = (userId: number, requests: ReassignmentRequest[]) => {
    const data = fromPairs(requests.map(request => [request.taskId, request.userId]) as any);
    return this.api.put(`/staff/tasks/user/${userId}/reassign`, data);
  };

  fetchAssignmentTasks = ({ clientId, assignmentId }: AssignmentIdentity) => {
    const path = `/staff/tasks/client/${clientId}/assignment/${assignmentId}/list`;
    return this.api.get(path).pipe(map(payload => payload.tasks));
  };

  fetchNewAssignmentTaskForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    const path = `/staff/tasks/client/${clientId}/assignment/${assignmentId}/new`;
    return this.api.get(path).pipe(map(payload => payload.form));
  };

  createAssignmentTask = ({ clientId, assignmentId }: AssignmentIdentity, data) => {
    return this.api.post(`/staff/tasks/client/${clientId}/assignment/${assignmentId}`, data);
  };

  fetchAssignmentTask = ({ clientId, assignmentId }: AssignmentIdentity, taskId: number) => {
    return this.api
      .get(`/staff/tasks/client/${clientId}/assignment/${assignmentId}/task/${taskId}`)
      .pipe(map(payload => payload.form));
  };

  updateAssignmentTask = ({ clientId, assignmentId }: AssignmentIdentity, taskId: number, data) => {
    return this.api.patch(
      `/staff/tasks/client/${clientId}/assignment/${assignmentId}/task/${taskId}`,
      data
    );
  };
}
