import { Component, Input } from '@angular/core';

@Component({
  selector: 'collapsible-block',
  templateUrl: 'collapsible-block.component.html',
  styleUrls: ['collapsible-block.component.scss']
})
export class CollapsibleBlockComponent {
  @Input() collapsed: boolean;
}
