import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'tabs',
  templateUrl: 'tabs.component.html',
  styleUrls: ['tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsComponent {
  @Input() items: TabItem[];
  @Input() padding = '14px';

  get linkStyle() {
    return {
      'padding-left': this.padding,
      'padding-right': this.padding
    };
  }
}

export interface TabItem {
  readonly label: string;
  readonly link: string;
  readonly icon: string;
  readonly exact?: boolean;
}
