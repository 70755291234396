<btn
  htmlType="submit"
  [icon]="icon"
  [disabled]="isDisabled"
  [loading]="isLoading"
  (click)="onSubmit.emit()"
>
  <ng-content></ng-content>
</btn>

<div *ngIf="isCancellable" class="back">
  <ng-container *ngIf="cancelLink">
    or
    <a
      [routerLink]="cancelLink"
      [queryParams]="cancelQueryParams"
      (click)="cancel.emit()"
      >{{ cancelLabel }}</a
    >
  </ng-container>

  <ng-container *ngIf="!cancelLink">
    or
    <a (click)="cancel.emit()">{{ cancelLabel }}</a>
  </ng-container>
</div>
